import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import Header from '../components/Header';

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const siteTitle = this.props.data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Header page="blog"/>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <section id="article" className="bg-light">
          <div className="container">
            <article
              className={`post-content ${post.frontmatter.thumbnail ||
                `no-image`}`}
            >
              <header className="post-content-header">
                <h1 className="post-content-title">{post.frontmatter.title}</h1>
              </header>

              {post.frontmatter.description && (
                <p class="post-content-excerpt">
                  {post.frontmatter.description}
                </p>
              )}

              <div
                className="post-content-body"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
            </article>
          </div>
        </section>
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 1360) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
